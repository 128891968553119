<script>
import {defineComponent} from 'vue'
import {categories, types, statuses} from "@/views/pages/trains/utils.js";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import SelectStations from "@/components/custom/SelectStations.vue"
import axios from "axios";
import Swal from "sweetalert2";

import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "EditTab",
  emits: ['trainUpdated'],
  components: {
    flatPickr, SelectStations,
    Multiselect
  },
  data() {
    return {
      categories: categories,
      types: types,
      statuses: statuses,
      timeConfig: {
        enableTime: false,
        altInput: true,
        dateFormat: "Z",
        altFormat: "d M, Y",
        mode: "range",
      },

      train: {},

      groupIdSelect: {
        options: [],
        placeholder: "Select group",
      }
    }
  },
  computed: {
    trainComputed() {
      if (Object.keys(this.train).length === 0) {
        return {}
      }
      return {
        ...this.train,
        date: this.train.departure_date + " to " + this.train.expected_arrival_date,
      }
    },
    train_slug() {
      return this.$route.params.train_slug
    }
  },
  methods: {
    onStationChange(station) {
      if (station.option === 'departure') {
        this.trainComputed.departure_station = {
          id: station.value.value,
          name: station.value.name,
          code: station.value.code
        }
      } else if (station.option === 'destination') {
        this.trainComputed.destination_station = {
          id: station.value.value,
          name: station.value.name,
          code: station.value.code
        }
      }
    },
    async editTrain() {
      let date1 = null
      let date2 = null
      let dates = this.trainComputed.date.split(" to ")

      if (dates.length === 2) {
        date1 = this.formatDate(new Date(dates[0]));
        date2 = this.formatDate(new Date(dates[1]));
      } else {
        date1 = this.formatDate(new Date(dates[0]));
        date2 = date1
      }

      let info = {
        "name": this.trainComputed.name,
        "group_id": this.trainComputed.group_id,
        "type": this.trainComputed.type,
        "route": this.trainComputed.route,
        "color": this.trainComputed.color,
        "status": this.trainComputed.status,
        "departure_date": date1,
        "expected_arrival_date": date2,
        "description": this.trainComputed.description,
        "departure_station_id": this.trainComputed.departure_station.id,
        "destination_station_id": this.trainComputed.destination_station.id,
        "current_station_id": this.trainComputed.current_station ? this.trainComputed.current_station.id : null,
      }

      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      try {
        await axios.put('/train/' + this.trainComputed.slug + '/update/', info)
        let newTrainSlug = this.trainComputed.name.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '-')
        this.$emit('trainUpdated', newTrainSlug)
        await this.$router.push({
          name: 'train_details_settings',
          params: {train_slug: newTrainSlug}
        })
        await this.getTrainBySlug(newTrainSlug)
        await Toast.fire({
          icon: 'success',
          title: 'Updated successfully',
        })
      } catch {
        await Toast.fire({
          icon: 'error',
          title: 'Something went wrong',
        })
      }
    },
    async getTrainBySlug(train_slug) {
      try {
        let response = await axios.get(`/train/${train_slug}/`)
        this.train = response.data
        this.train.date = this.train.departure_date + " to " + this.train.expected_arrival_date
      } catch (e) {
        console.log(e)
      }
    },
    async getGroupOptions() {
      try {
        let response = await axios.get('/third_party/railway/groups/')
        this.groupIdSelect.options = response.data.groups.group.map((option) => {
          return {
            value: option.group_id,
            label: option.group_name,
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  },
  async mounted() {
    await this.getTrainBySlug(this.train_slug)
    await this.getGroupOptions()
  }
})
</script>

<template>
  <div class="tab-pane fade"
       :class="{'show active': $route.name === 'train_details_settings'}"
       id="edit-tab" role="tabpanel">
    <b-card no-body>
      <b-card-body>
        <form @submit.prevent="editTrain()" v-if="Object.keys(trainComputed).length > 0">
          <div class="row text-dark">
            <div class="col-3">
              <div class="mb-3">
                <label for="trainName" class="form-label">Train Name</label>
                <input type="text" class="form-control" placeholder="Enter your firstname"
                       id="trainName" v-model="trainComputed.name"
                >

              </div>
            </div><!--end col-->
            <div class="col-3">
              <div class="mb-3">
                <label for="trainGroup" class="form-label">Group</label>
                <Multiselect v-model="trainComputed.group_id" v-bind="groupIdSelect"/>
              </div>
            </div><!--end col-->
            <div class="col-3">
              <div class="mb-3">
                <label for="trainType" class="form-label">Train Type</label>
                <select v-model="trainComputed.type" class="form-select" id="trainType">
                  <option v-for="option in types" :key="option" :value="option.value">
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-3">
              <div class="mb-3">
                <label for="trainStatus" class="form-label">Train Type</label>
                <select v-model="trainComputed.status" class="form-select" id="trainStatus">
                  <option v-for="option in statuses" :key="option" :value="option.value">
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-4">
              <div class="mb-3">
                <label for="trainColor" class="form-label">Train Color</label>
                <select v-model="trainComputed.color" class="form-select" id="trainColor">
                  <option v-for="option in categories" :key="option" :value="option.value">
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-4">
              <div class="mb-3">
                <label for="trainRoute" class="form-label">Train Type</label>
                <input type="text" class="form-control" placeholder="Enter your firstname"
                       id="trainRoute" v-model="trainComputed.route"
                >
              </div>
            </div>
            <div class="col-4">
              <div class="mb-3">
                <label for="trainDate" class="form-label">ETD ~ ETA</label>
                <flat-pickr placeholder="Select date"
                            :config="timeConfig"
                            v-model="trainComputed.date"
                            class="form-control flatpickr-input w-100"
                >
                </flat-pickr>
              </div>
            </div>
            <SelectStations
                @onSelect="onStationChange"
                :current_departure="{
                                    id: trainComputed.departure_station.id,
                                    name: trainComputed.departure_station.name,
                                    code: trainComputed.departure_station.code,
                                }"
                :current_destination="{
                                    id: trainComputed.destination_station.id,
                                    name: trainComputed.destination_station.name,
                                    code: trainComputed.destination_station.code,
                                }"
                :ratio="[3,3,3,3]"/>
            <div class="col-12">
              <div class="mb-3">
                <label for="trainDescription" class="form-label">Train Description</label>
                <textarea id="trainDescription" class="form-control" :value="trainComputed.description"
                          rows="6"/>
              </div>
            </div>
            <div class="col-12 text-end">
              <b-button type="submit" variant="success">Save Train</b-button>
            </div>
          </div>
        </form>
        <div class="text-center py-5" v-else>
          <div class="mt-2">
            <b-spinner/>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<style scoped>

</style>